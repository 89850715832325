/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { token } from '@headout/pixie/tokens';

import TYPE_LABELS from 'Static/typography/labels';

import {
	LocalizeText,
	ReadMoreBtn,
	ReviewContent,
	ReviewerCountryName,
	ReviewWrapper,
	StyledLink,
} from '../reviewElement/style';

export const Container = styled.div`
	margin: auto auto 0;
	padding: 4.5rem 0 2.625rem;
	max-width: 75rem;
	width: 100%;

	.arrow-button-wrapper {
		top: 11.25rem;
	}

	@media (max-width: 768px) {
		padding: 0 0 2rem;
	}
`;

export const HeadingWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;

	@media (max-width: 768px) {
		margin-bottom: 1.5rem;
	}
`;

export const Heading = styled.h2`
	${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};

	@media (max-width: 768px) {
		padding: 0 1.5rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
	}
`;

const darkVariantStyles = css`
	background: #150029;

	${Heading} {
		color: ${token('colors.core.primary.white')};
	}

	${ReviewWrapper} {
		border: 1px solid #ffffff33;
		background: #ffffff1a;
		backdrop-filter: blur(12px);

		.reviewer-name.block {
			color: ${token('colors.core.primary.white')};
		}

		${ReviewerCountryName} {
			color: ${token('colors.core.primary.white')};
		}

		.review-time {
			color: #ffffffcc;
		}

		${ReviewContent}, ${ReadMoreBtn}, ${LocalizeText} {
			color: #ffffffcc;
		}

		${StyledLink} {
			border-color: ${token('colors.core.primary.white') + '33'};
			color: ${token('colors.core.primary.white')};
			&.block {
				padding-top: 1rem;
				margin-top: 1rem;
			}
		}
	}
`;

export const GradientWrapper = styled.div<{
	isCollectionPage?: boolean;
	$isDarkVariant?: boolean;
}>`
	background: linear-gradient(
		180deg,
		rgba(248, 246, 255, 0) 0%,
		#f8f6ff 100%
	);

	${({ $isDarkVariant }) => $isDarkVariant && darkVariantStyles}

	@media (max-width: 768px) {
		${({ isCollectionPage }) =>
			isCollectionPage && 'margin-bottom: 3.75rem'};
	}
`;

export const LottieContainer = styled.div`
	display: inline-block;
	max-width: 2rem;
	margin-left: 0.625rem;
	transform: translateY(0.5rem);

	@media (max-width: 768px) {
		transform: none;
	}
`;

export const MobileCarousel = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	padding: 0 0.75rem;

	&::-webkit-scrollbar {
		display: none;
	}
`;
