import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import Conditional from 'Components/common/conditional';
import type { CarouselRefActions } from 'Components/common/lateralCarousel';
import Modal from 'Components/common/modal';
import RenderOneOf from 'Components/common/renderOneOf';
import ReviewElement from 'Components/common/reviewElement';
import {
	ModalContainer,
	ModalStoryContainer,
} from 'Components/common/travelerMediaTiles/style';

import { useAutoPlayingLottieAnimation } from 'Hooks/useAutoPlayingLottieAnimation';
import useOnScreen from 'Hooks/useOnScreen';
import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { trackEvent, trackSectionViews } from 'Utils/analytics';
import { getRandomImage } from 'Utils/reviewUtils';
import {
	getCityReviews,
	getCollectionReviews,
	getCurrentLanguageCode,
	getPersonaReviews,
} from 'Utils/stateUtils';

import type { TReducedReviewWithMedia } from 'ReduxTypes/travelerMedia';

import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	COLLECTION_PAGE,
	PERSONA_PAGE,
} from 'Constants/analytics';
import { HEART_URL } from 'Constants/constants';
import { strings } from 'Constants/strings';

import type { TProps } from './interface';
import {
	Container,
	GradientWrapper,
	Heading,
	HeadingWrapper,
	LottieContainer,
	MobileCarousel,
} from './style';

const LateralCarousel = dynamic(
	() => import('Components/common/lateralCarousel'),
);
const MultiSlideStoryCarousel = dynamic(
	() =>
		import(
			/* webpackChunkName: "MultiSlideStoryCarousel" */ 'Components/common/multiSlideStoryCarousel'
		),
);
const NavigationButtons = dynamic(
	() => import('Components/desktop/navigationButtons'),
);

const ReviewSection = ({
	isDesktop = false,
	city,
	collection,
	persona,
	variant = 'light',
	heading,
	customIllustration,
	index,
	isDayTripsCollection = false,
}: TProps) => {
	const [reviewIndex, setReviewIndex] = useState(0);
	const [mediaIndex, setMediaIndex] = useState(0);
	const [dataRecorded, setDataRecorded] = useState(false);
	const [reviewsWithMedia, setReviewWithMedia] = useState<
		TReducedReviewWithMedia[]
	>([]);
	const [showStoryCarousel, setShowStoryCarousel] = useState(false);

	const lottieContainerRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const { currentLanguage, reviewData } = useSelector(state => {
		const currentLanguage = getCurrentLanguageCode(state) as string;
		let reviewData;
		if (collection) {
			reviewData = getCollectionReviews(state, collection);
		} else if (persona) {
			reviewData = getPersonaReviews(state, city!, persona);
		} else {
			reviewData = getCityReviews(state, city!);
		}
		return { currentLanguage, reviewData };
	});

	const isOnScreen = useOnScreen({
		ref: containerRef,
		options: { threshold: 0.25 },
	});
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();
	const desktopReviewCarouselRef = useRef<CarouselRefActions>(null);

	useEffect(() => {
		if (!reviewData) return;
		const { reviews } = reviewData;

		const reviewsWithMedia: TReducedReviewWithMedia[] = reviews
			.filter(
				({ reviewMedias }) => reviewMedias && reviewMedias.length > 0,
			)
			.map(review => {
				const reviewWithMedia = {
					...review,
					reviewMedias: review.reviewMedias!,
				};
				return reviewWithMedia;
			});

		setReviewWithMedia(reviewsWithMedia);
	}, [city, collection]);

	useEffect(() => {
		if (dataRecorded) return;
		if (isOnScreen) {
			if (persona) {
				trackSectionViews(
					ANALYTICS_EVENTS.PERSONA.SECTION_VIEWED,
					PERSONA_PAGE.REVIEWS,
					3,
				)(containerRef);
			} else if (isDayTripsCollection) {
				trackEvent({
					eventName: ANALYTICS_EVENTS.COLLECTION_PAGE_SECTION_VIEWED,
					[ANALYTICS_PROPERTIES.SECTION]: COLLECTION_PAGE.REVIEWS,
				});
			} else {
				trackEvent({
					eventName: 'Review Section Viewed',
					...(index !== undefined && {
						Ranking: Math.floor(index / 4),
						[ANALYTICS_PROPERTIES.INSERT_TYPE]: 'Reviews',
					}),
				});
			}
			setDataRecorded(true);
		}
	}, [dataRecorded, isOnScreen]);

	useAutoPlayingLottieAnimation({
		ref: lottieContainerRef,
		animationProperties: {
			loop: true,
			path: HEART_URL,
		},
	});

	if (!reviewData || reviewData.total < 4) return null;

	const { reviews } = reviewData;

	const onImageClick = (index: number, id: number) => {
		const reviewIndex = reviewsWithMedia.findIndex(v => v.id === id);
		if (reviewIndex === -1) return false;
		setReviewIndex(reviewIndex);
		setMediaIndex(index);
		setShowStoryCarousel(true);
		return false;
	};

	const reviewItems = reviews.map((review, reviewIndex) => (
		<ReviewElement
			key={`review-section-cc-${reviewIndex}`}
			currentLanguage={currentLanguage}
			{...review}
			reviewerImageUrl={
				review.reviewerImgUrl ??
				getRandomImage(review.nonCustomerName ?? '')
			}
			isExpanded
			onImageClick={index => onImageClick(index, review.id)}
		/>
	));

	return (
		<GradientWrapper
			id='review-section-wrapper'
			isCollectionPage={!!collection}
			$isDarkVariant={variant === 'dark'}
		>
			<ModalContainer>
				<Modal
					open={showStoryCarousel}
					containerClassName={'story-modal-container'}
					contentClassName={'core-modal-content'}
					onRequestClose={() => setShowStoryCarousel(false)}
					// @ts-expect-error TS(2769): No overload matches this call.
					dimensionsStyle={{
						marginTop: '10rem',
						marginBottom: '10rem',
					}}
				>
					<ModalStoryContainer>
						<MultiSlideStoryCarousel
							reviewIndex={reviewIndex}
							mediaIndex={mediaIndex}
							onUpdateMediaIndex={index => setMediaIndex(index)}
							onUpdateReviewIndex={index => setReviewIndex(index)}
							reviews={reviewsWithMedia}
							isDesktop={isDesktop}
							hideCarousel={() => setShowStoryCarousel(false)}
						/>
					</ModalStoryContainer>
				</Modal>
			</ModalContainer>
			<Container className='review-section-container' ref={containerRef}>
				<HeadingWrapper className='review-section-heading-wrapper'>
					<Heading className='review-section-heading'>
						{heading ?? strings.REVIEW_SECTION.HEADING}{' '}
						{customIllustration ?? (
							<LottieContainer
								ref={lottieContainerRef}
								role='none'
							/>
						)}
					</Heading>
					<Conditional if={reviewItems?.length > 4 && isDesktop}>
						<NavigationButtons
							showLeftArrow={showLeftArrow}
							showRightArrow={showRightArrow}
							prevSlide={
								desktopReviewCarouselRef.current?.prevSlide
							}
							nextSlide={
								desktopReviewCarouselRef.current?.nextSlide
							}
						/>
					</Conditional>
				</HeadingWrapper>

				<RenderOneOf positionalConditions={[isDesktop, !isDesktop]}>
					<LateralCarousel
						elementsToShow={4}
						elementsToSlide={4}
						isCardsArrowsRequired={false}
						isCollectionCarousel={true}
						carouselRef={desktopReviewCarouselRef}
						onSlideChanged={onSlideChanged}
					>
						{reviewItems}
					</LateralCarousel>
					<MobileCarousel className='review-section-carousel'>
						{reviewItems}
					</MobileCarousel>
				</RenderOneOf>
			</Container>
		</GradientWrapper>
	);
};

export default ReviewSection;
